import axios from "axios";
import { FinancialInterest } from "../types/nocodb";

const API_URL = import.meta.env.VITE_REACT_APP_NOCODB_API_URL;
const API_TOKEN = import.meta.env.VITE_REACT_APP_NOCODB_API_TOKEN;

const nocodbApi = axios.create({
  baseURL: API_URL,
  headers: {
    "xc-token": API_TOKEN,
  },
});

export const TABLE_IDS = {
  USERS: "mp3kfeza4acpmwh",
  GENERAL_FEEDBACK: "mg7gpqvapff6r22",
  SOLUTION_FEEDBACK: "myl5lky7ck14c7y",
  SOLUTIONS: "mj5e0isyx8poscz",
  INTEGRATION_FEEDBACK: "mwbj5cox76or375",
  DESKTOP_FEEDBACK: "mm0rin98hs3o0te",
  FINANCIAL: "mig6hjfvr5gwnjc",
  APPLICATIONS: "m0qhkbuncyc5ni3",
};

export const getGeneralFeedback = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.GENERAL_FEEDBACK}/records`,
    );
    return response.data.list;
  } catch (error) {
    console.error("Error fetching general feedback:", error);
    throw error;
  }
};

export const getSolutionFeedback = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.SOLUTION_FEEDBACK}/records`,
    );
    return response.data.list;
  } catch (error) {
    console.error("Error fetching solution feedback:", error);
    throw error;
  }
};

export const getDesktopFeedback = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.DESKTOP_FEEDBACK}/records`,
    );
    return response.data.list;
  } catch (error) {
    console.error("Error fetching desktop feedback:", error);
    throw error;
  }
};

export const updateDesktopFollowUp = async (
  id: number,
  followedUp: boolean,
) => {
  try {
    await nocodbApi.patch(
      `/api/v2/tables/${TABLE_IDS.DESKTOP_FEEDBACK}/records/${id}`,
      { FollowUp: followedUp ? "Y" : "N" },
    );
  } catch (error) {
    console.error("Error updating desktop follow-up:", error);
    throw error;
  }
};

export const getIntegrationFeedback = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.INTEGRATION_FEEDBACK}/records`,
    );
    return response.data.list;
  } catch (error) {
    console.error("Error fetching integration feedback:", error);
    throw error;
  }
};

export const getUserProgress = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.USERS}/records`,
    );
    return response.data.list;
  } catch (error) {
    console.error("Error fetching user progress:", error);
    throw error;
  }
};

export const getApplications = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.APPLICATIONS}/records`,
    );
    return response.data.list;
  } catch (error) {
    console.error("Error fetching applications:", error);
    throw error;
  }
};

export const getFinancialInterests = async (client: string) => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.FINANCIAL}/records`,
      {
        params: {
          where: `(Client,eq,${client})`,
        },
      },
    );
    return response.data.list || [];
  } catch (error) {
    console.error("Error fetching financial interests:", error);
    throw error;
  }
};

export const updateFinancialInterests = async (data: FinancialInterest) => {
  try {
    if (data.Id) {
      // Update existing record
      const response = await nocodbApi.patch(
        `/api/v2/tables/${TABLE_IDS.FINANCIAL}/records`,
        [data],
      );
      return response.data;
    } else {
      // Create new record
      const response = await nocodbApi.post(
        `/api/v2/tables/${TABLE_IDS.FINANCIAL}/records`,
        data,
      );
      return response.data;
    }
  } catch (error) {
    console.error("Error updating financial interests:", error);
    throw error;
  }
};
