
import React from 'react';

export function SweepstakesPage() {
  return (
    <div className="flex-1 p-8">
      <h1 className="text-2xl font-bold mb-6">Sweepstakes</h1>
    </div>
  );
}
